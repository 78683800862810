<template>
  <div :class="{ 'content-wrapper': !isTab }" class="talent-analysis-report">
    <div>
      <h1 v-show="!isTab" class="mb-3  ">Talent Analysis Report</h1>

      <b-button
        @click="$router.push('/printTalentAnalysis/' + $route.params.reportId)"
        class=" "
        >Stampa</b-button
      >
      <div v-show="!report_present" class="py-3">
        This user has not a Talent Analysis Report associated
      </div>
      <b-overlay
        :show="showOverlay && report_present"
        rounded="sm"
        class="overlay-placeholder"
      >
        <!-- <div v-show="!showOverlay">
        <div class="d-flex flex-column align-items-center mb-5" v-if="!idUser"> -->
        <div>
          <div class="d-flex flex-column align-items-center mb-5">
            <!-- Wrapper del grafico -->
            <div class="chart-wrapper" ref="chartdiv">
              <div
                v-if="user.avatar_url"
                class="photo-cicrle"
                :style="{ backgroundImage: 'url(' + user.avatar_url + ')' }"
              ></div>
              <div
                v-else
                class="
                photo-cicrle
                bg-transparent
                d-flex
                align-items-center
                justify-content-center
              "
              >
                <b-spinner></b-spinner>
              </div>
            </div>
            <h3>{{ (user.nome || "") + " " + (user.cognome || "") }}</h3>
          </div>
          <div
            class="row supergroup  "
            v-for="(supergroup, i) in supergroups"
            :key="'data-supergroup-' + i"
          >
            <div
              v-for="group in supergroup"
              :key="group.GroupName"
              class="mt-3"
              :class="['col-' + 12 / supergroup.length, { 'px-2': !isTab }]"
            >
              <h4>{{ group.GroupName }}</h4>
              <div v-for="(item, i) in group.Data" :key="'a' + i" class="mb-2">
                <div class="text-label d-flex justify-content-between mb-2">
                  <span>{{ item.LabelSx }}</span>
                  <span>{{ item.LabelDx }}</span>
                </div>
                <b-progress max="9.75" class="mb-4">
                  <b-progress-bar
                    :value="item.Valore"
                    show-value
                    :style="{ backgroundColor: item.color }"
                  ></b-progress-bar>
                </b-progress>
              </div>
            </div>
          </div>

          <div class=" " v-if="!showCheckboxes">
            <div v-if="item.Premessa" class="mt-4 row-supergroup">
              <h4>Premessa</h4>
              {{ item.Premessa }}
            </div>
            <div
              class="row-supergroup"
              v-for="(group, i) in item.TextGroup"
              :key="'text-supergroup-' + i"
            >
              <div>
                <h4 class="mt-5">
                  {{
                    group.GroupName.charAt(0).toUpperCase() +
                      group.GroupName.slice(1)
                  }}
                </h4>
                <div v-for="(column, i) in group.Columns" :key="'column-' + i">
                  <div v-if="column.Valori != null">
                    <div v-for="value in column.Valori" :key="value.ID">
                      <div v-if="value.attivo">
                        <strong>
                          <p class="mt-3">
                            {{
                              value.chiave.charAt(0).toUpperCase() +
                                value.chiave.slice(1)
                            }}
                          </p>
                        </strong>
                        {{
                          value.testo.charAt(0).toUpperCase() +
                            value.testo.slice(1)
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="item.Conclusioni" class="mt-6 row-supergroup mb-5">
              <h4>Conclusioni</h4>
              {{ item.Conclusioni }}
            </div>
          </div>

          <b-row class=" " v-if="showCheckboxes">
            <b-col :md="isTab ? 7 : 9">
              <h4 class="text-uppercase font-weight-bold">PREMESSA</h4>
              <b-form-group class="mb-5">
                <b-form-textarea
                  rows="8"
                  v-model="item.Premessa"
                ></b-form-textarea>
              </b-form-group>
              <div
                v-for="(checkGroup, i) in item.TextGroup"
                :key="'textAreaGroup-' + i"
                class="mb-5"
              >
                <h4 class="text-uppercase font-weight-bold">
                  {{ checkGroup.GroupName }}
                </h4>
                <template v-for="(checkRow, j) in checkGroup.Columns[1].Valori">
                  <b-form-group
                    class="mb-5"
                    :key="'textAreaRowSx-' + i + '-' + j"
                    v-if="checkRow.attivo"
                  >
                    <template #label>
                      <span class="text-uppercase font-weight-bold">
                        {{ checkRow.chiave }}
                      </span>
                    </template>
                    <b-form-textarea
                      rows="5"
                      v-model="checkRow.testo"
                    ></b-form-textarea>
                  </b-form-group>
                  <b-form-group
                    class="mb-5"
                    :key="'textAreaRowDx-' + i + '-' + j"
                    v-if="checkGroup.Columns[0].Valori[j].attivo"
                  >
                    <template #label>
                      <span class="text-uppercase font-weight-bold">
                        {{ checkGroup.Columns[0].Valori[j].chiave }}
                      </span>
                    </template>
                    <b-form-textarea
                      rows="5"
                      v-model="checkGroup.Columns[0].Valori[j].testo"
                    ></b-form-textarea>
                  </b-form-group>
                </template>
              </div>
              <h4 class="text-uppercase font-weight-bold">CONCLUSIONI</h4>
              <b-form-group class="mb-5">
                <b-form-textarea
                  rows="8"
                  v-model="item.Conclusioni"
                ></b-form-textarea>
              </b-form-group>
            </b-col>
            <b-col :md="isTab ? 5 : 3">
              <div v-if="item.Warning.length > 0">
                CORRELAZIONI
                <ul class="mt-3">
                  <li v-for="(item, i) in item.Warning" :key="i">
                    {{ item.tipo_correlazione }} - {{ item.nome }}
                  </li>
                </ul>
              </div>
              <div
                v-for="(checkGroup, i) in item.TextGroup"
                :key="'checkGroup-' + i"
                class="mb-4"
              >
                <div
                  v-show="
                    checkGroup.GroupName !=
                      'Suggerimenti per il miglioramento personale'
                  "
                >
                  <p class="text-uppercase">{{ checkGroup.GroupName }}</p>
                  <b-row>
                    <b-col md="6">
                      <b-form-checkbox
                        v-for="(checkRow, j) in checkGroup.Columns[1].Valori"
                        :key="'checkRowSx-' + i + '-' + j"
                        v-model="checkRow.attivo"
                        @input="
                          setSuggerimenti(
                            checkGroup.GroupName,
                            1,
                            checkRow.chiave,
                            checkRow.attivo
                          )
                        "
                      >
                        {{ checkRow.chiave }}
                      </b-form-checkbox>
                    </b-col>
                    <b-col md="6">
                      <b-form-checkbox
                        v-for="(checkRow, j) in checkGroup.Columns[0].Valori"
                        :key="'checkRowDx-' + i + '-' + j"
                        v-model="checkRow.attivo"
                        @input="
                          setSuggerimenti(
                            checkGroup.GroupName,
                            0,
                            checkRow.chiave,
                            checkRow.attivo
                          )
                        "
                      >
                        {{ checkRow.chiave }}
                      </b-form-checkbox>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-form-group v-if="showCheckboxes" class="mt-3 mb-5">
            <template #label>
              <h4 class="text-uppercase font-weight-bold">STATO</h4>
            </template>
            <b-form-radio-group
              id="btn-radios-2"
              v-model="item.ID_Stato"
              :options="stato_options"
              button-variant="outline-secondary"
              buttons
            ></b-form-radio-group>
          </b-form-group>
          <b-button
            v-if="showCheckboxes"
            variant="primary"
            size="lg"
            class="mt-3"
            @click="saveItem"
            >Save report</b-button
          >
        </div>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import AxiosService from "../axiosServices/AxiosService"
import { hslToHex } from "@/utils/hslToRgb.js"
import * as am5 from "@amcharts/amcharts5"
import * as am5xy from "@amcharts/amcharts5/xy"
import * as am5radar from "@amcharts/amcharts5/radar"
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated"
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting"
export default {
  components: {},
  props: {
    idUser: Number,
    isTab: {
      value: Boolean,
      default: false,
    },
    showFlower: {
      value: Boolean,
      default: true,
    },
    showCheckboxes: {
      value: Boolean,
      default: true,
    },
  },

  // ["idUser", "isTab", "showFlower"],
  data() {
    return {
      idReport: this.$route.params.reportId,
      item: {},
      showOverlay: true,
      root: null,

      user: {},

      currentPage: 1,
      perPage: 10,
      filter: "",

      hueList: {
        AVERE: 207,
        ESSERE: 342,
        FARE: 130,
        "INDICI DELLO STILE DI RISPOSTA": 250,
        Orientamento: 250,
        "Ruolo nel Gruppo": 250,
        "SCALE DERIVATE SPECIALI": 250,
        "Stile Collaborazione": 190,
        "Stile di Influenzamento": 190,
        "Stile Leadership": 190,
      },

      report_present: true,
      stato_options: [
        { text: "Non pubblicato", value: 2 },
        { text: "Pubblicato", value: 3 },
      ],
    }
  },
  service: null,
  userService: null,
  created() {
    this.service = new AxiosService("AnalisiDelTalento")
    this.userService = new AxiosService("User")
  },
  mounted() {
    this.$nextTick(() => {
      console.log("CONTROLLO ID", this.idUser)
      if (this.idUser) {
        this.service
          .readCustomEndpoint("AnalisiDelTalentoByUser", this.idUser)
          .then((data) => {
            if (data) {
              this.item = data
              this.userService.readById(this.idUser).then((data) => {
                this.user = data
                this.preset()
              })
              this.report_present = true
            } else {
              this.report_present = false
            }
          })
          .catch(() => {
            this.report_present = false
            this.showOverlay = false
          })
      } else {
        this.service.readById(this.idReport).then((data) => {
          this.item = data
          this.userService.readById(this.item.ID_User).then((data) => {
            this.user = data
            this.preset()
          })
        })
      }
    })
  },
  methods: {
    preset() {
      this.item.DataGroup.forEach((element) => {
        let hue = null
        if (element.GroupName) {
          hue = this.hueList[element.GroupName]
          if (
            typeof hue !== "undefined" &&
            element.Data &&
            Array.isArray(element.Data)
          ) {
            element.Data = this.associateColors(element.Data, hue).slice()
          }
        }
      })
      this.showOverlay = false
      this.setChart()
    },
    getCompanyName(id) {
      const item = this.companyList.find((x) => x.value === id)
      return item ? item.text : ""
    },

    saveItem() {
      if (this.item && this.item.ID) {
        this.service
          .putCustomEndpoint(
            "AnalisiDelTalentoSetText",
            this.item.ID,
            this.item
          )
          .then((response) => {
            if (response.ID == this.item.ID) {
              this.$successToast("Report saved")
            } else {
              this.$errorToast()
              return false
            }
          })
          .catch(() => {
            this.$errorToast()
            return false
          })
      } else {
        this.$errorToast()
        return false
      }
    },

    associateColors(list, hue) {
      let saturation = 100
      const startLightness = 30
      const endLightness = 85
      const range = endLightness - startLightness
      const step = Math.floor(range / list.length - 1)
      const stepColor = 4
      let currentLightness = startLightness
      hue = hue - Math.floor((stepColor * list.length) / 2)
      hue = hue < 0 ? 360 + hue : hue
      list.forEach((element) => {
        element.color = hslToHex(hue, saturation, currentLightness)
        currentLightness += step
        hue = (hue + stepColor) % 360
      })
      return list
    },

    setChart() {
      if (!this.showFlower) return false
      let root = am5.Root.new(this.$refs.chartdiv)
      root.setThemes([am5themes_Animated.new(root)])

      // Create chart
      // https://www.amcharts.com/docs/v5/charts/xy-chart/
      var chart = root.container.children.push(
        am5radar.RadarChart.new(root, {
          panX: false,
          panY: false,
          wheelX: "none",
          wheelY: "none",
          startAngle: -90,
          endAngle: 270,
          //raggio esterno e raggio interno
          radius: 165,
          innerRadius: 85,
        })
      )

      // Add scrollbar
      // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
      chart.set(
        "scrollbarX",
        am5.Scrollbar.new(root, {
          orientation: "horizontal",
          exportable: false,
        })
      )

      // Create axes
      // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
      var xRenderer = am5radar.AxisRendererCircular.new(root, {
        minGridDistance: 30,
      })

      xRenderer.grid.template.set("forceHidden", true)
      xRenderer.labels.template.set("forceHidden", true)

      var xAxis = chart.xAxes.push(
        am5xy.CategoryAxis.new(root, {
          maxDeviation: 0,
          categoryField: "category",
          renderer: xRenderer,
        })
      )

      var yRenderer = am5radar.AxisRendererRadial.new(root, {})
      yRenderer.grid.template.set("forceHidden", true)
      yRenderer.labels.template.set("forceHidden", true)

      var yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          //maxDeviation: 0.3,
          min: 0,
          max: 10,
          renderer: yRenderer,
        })
      )

      // Add series
      // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
      var series = chart.series.push(
        am5radar.RadarColumnSeries.new(root, {
          name: "Series 1",
          sequencedInterpolation: true,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: "value",
          categoryXField: "category",
        })
      )

      // Rounded corners for columns
      series.columns.template.setAll({
        cornerRadius: 5,
        tooltipText: "{categoryX}: {valueY}",
        templateField: "columnSettings",
      })

      xAxis.data.setAll(this.chartData)
      series.data.setAll(this.chartData)

      // Make stuff animate on load
      // https://www.amcharts.com/docs/v5/concepts/animations/
      series.appear(1000)
      chart.appear(1000, 100)

      this.root = root
    },
    setSuggerimenti(group, col, key, state) {
      if (group == "aree di miglioramento rispetto al ruolo") {
        const suggerimenti = this.item.TextGroup.find((obj) => {
          return obj.GroupName === "Suggerimenti per il miglioramento personale"
        })
        let valore = suggerimenti.Columns[col].Valori.find((obj) => {
          return obj.chiave === key
        })
        valore.attivo = state
      }
    },
  },
  computed: {
    supergroups() {
      let supergroupsArray = [[], [], []]
      if (this.item.DataGroup && Array.isArray(this.item.DataGroup)) {
        this.item.DataGroup.forEach((element) => {
          const nome = element.GroupName
          if (nome == "AVERE" || nome == "ESSERE" || nome == "FARE") {
            supergroupsArray[0].push(element)
          } else if (nome.startsWith("Stile")) {
            supergroupsArray[2].push(element)
          } else {
            supergroupsArray[1].push(element)
          }
        })
      }
      return supergroupsArray
    },
    chartData() {
      //variabile dei dati del grafico
      let data = []
      this.item.DataGroup.forEach((element) => {
        const nome = element.GroupName

        if (nome == "AVERE" || nome == "ESSERE" || nome == "FARE") {
          element.Data.forEach((x) => {
            let current = {}
            current.category = x.LabelSx + " - " + x.LabelDx
            current.value = x.Valore
            current.columnSettings = {
              fill: x.color,
              stroke: x.color,
            }
            data.push(current)
          })
        }
      })
      return data
    },
  },
}
</script>

<style scoped>
.sheet {
  margin: 0;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  page-break-after: always;
}

.sheet.padding-10mm {
  padding: 10mm;
}

.talent-analysis-page {
  .talent-list-button {
    width: 5.5rem;
  }
}
.talent-analysis-report {
  .progress {
    background-color: #e6e6e6 !important;
    border-radius: 8px !important;
    text-shadow: 1px 1px 1px #0005;
    font-weight: bold;
  }

  .photo-cicrle {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background-color: #888;
    background-size: cover;
    background-position: center;
    position: absolute;
    /*offset verticale da trovare sperimentando in base all'altezza del wrapper */
    top: 154px;
    left: 50%;
    transform: translateX(-50%);
  }

  .supergroup {
    margin-bottom: 5rem;
  }

  .text-label {
    font-size: 15px;
  }

  .chart-wrapper {
    width: calc(100%);
    /*altezza del wrapper chart*/
    height: 420px;
    position: relative;
  }
}
</style>
